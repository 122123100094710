<template>
  <Box component="nav" flex direction="col" data-test-id="lasso-menu">
    <template v-for="item in menuItems" :key="item.to">
      <Box v-if="item.divider" :data-test-id="item.dataTestId" class="px-2">
        <Divider />
      </Box>

      <MenuItem
        v-else-if="item.children.length === 0"
        :data-test-id="item.dataTestId"
        :selected="item.selected"
        :to="item.to"
        @click="item.onClick()"
      >
        <Box flex alignItems="center">
          <Icon :icon="item.icon" size="md" variant="outlined" />
          <Typography v-if="!collapsed" pl="2" variant="body2">
            {{ item.label }}
          </Typography>
        </Box>
      </MenuItem>

      <MenuAccordion
        v-else
        :data-test-id="item.wrapperDataTestId"
        :modelValue="itemOpened[item.to] ?? false"
        @update:modelValue="itemOpened[item.to] = $event"
      >
        <template #default="{ toggle, isOpen }">
          <MenuItem
            :to="item.to"
            :selected="item.selected"
            :data-test-id="item.dataTestId"
            type="button"
            @click="item.onClick(toggle)"
          >
            <Typography flex alignItems="center" justify="between" variant="body2">
              <Box flex alignItems="center" spaceX="2">
                <Icon :icon="item.icon" size="md" variant="outlined" />
                <Box v-if="!collapsed">
                  {{ item.label }}
                </Box>
              </Box>
              <Icon v-if="!collapsed" :icon="isOpen ? 'remove' : 'add'" />
            </Typography>
          </MenuItem>
        </template>

        <template v-if="!collapsed" #dropdown>
          <Box flex direction="col" pl="6">
            <MenuItem
              v-for="child in item.children"
              :key="child.to" :to="child.to"
              :selected="child.selected"
              :data-test-id="child.dataTestId"
              @click="child.onClick()"
            >
              <Typography variant="body2">
                {{ child.label }}
              </Typography>
            </MenuItem>
          </Box>
        </template>
      </MenuAccordion>
    </template>
  </Box>
</template>

<script lang="ts" setup>
import { Box, Divider, Icon, Typography } from '@lasso/luikit'
import { useGoogleTagManager } from '@lasso/shared/hooks'
import { computed, reactive, watch } from 'vue'
import { useAccessControl } from '@sidenav/hooks/useAccessControl'
import { truthy } from '@lasso/shared/utils'
import { generateGtmFixture } from '@sidenav/utils/gtm'
import { useRoute } from 'vue-router'
import { useSidenav } from '@sidenav/hooks/useSidenav'

import { MenuChildItemConfig, MenuItemConfigOrDivider } from './types'
import MenuAccordion from './MenuAccordion.vue'
import MenuItem from './MenuItem.vue'

const props = defineProps<{
  items: MenuItemConfigOrDivider[]
}>()

const route = useRoute()
const accessControl = useAccessControl()
const googleTagManager = useGoogleTagManager()
const { collapsed } = useSidenav()

const isVisible = (item: MenuChildItemConfig): boolean => {
  return item.visible?.(accessControl) ?? true
}

const isMatchingPath = (to: string): boolean => {
  return route.path.startsWith(`/${to.replace(/^\//, '')}`)
}

const buildChildItem = (item: MenuChildItemConfig) => {
  const gtm = item.gtm ? generateGtmFixture(item.gtm) : null
  const { to, includedTos, label } = item

  return {
    to,
    includedTos,
    label,
    selected: isMatchingPath(to),
    dataTestId: `lasso-menu-item-child-${to}`,
    onClick: () => {
      if (gtm) {
        googleTagManager.triggerEvent(gtm)
      }
    },
  }
}

const buildItem = (item: MenuItemConfigOrDivider) => {
  if (item.divider) {
    return { ...item, dataTestId: 'lasso-menu-item-divider' }
  }

  const allChildren = item.children ?? []
  const children = allChildren.filter(isVisible).map(buildChildItem)

  if (!isVisible(item) || (allChildren.length > 0 && children.length === 0)) {
    return null
  }

  const { to, includedTos, label, icon, ignoredTos = [] } = item
  const childTos = children.map(child => child.to)
  const gtm = item.gtm ? generateGtmFixture(item.gtm) : null
  const selected = ((isMatchingPath(to) || childTos.some(isMatchingPath)) && !ignoredTos.some(isMatchingPath))
    || (includedTos ? includedTos.some(isMatchingPath) : false)

  return {
    divider: false,
    to,
    label,
    icon,
    children,
    selected,
    wrapperDataTestId: `lasso-menu-item-wrapper-${to}`,
    dataTestId: `lasso-menu-item-${to}`,
    onClick: (toggle?: () => void) => {
      if (toggle) {
        toggle()
      }

      if (gtm) {
        googleTagManager.triggerEvent(gtm)
      }
    },
  }
}

const menuItems = computed(() => {
  return props.items
    .map(buildItem)
    .filter(truthy)
    .filter((item, index, items) => (item.divider && items[index + 1]?.divider) ? null : item)
    .filter(truthy)
})

const itemOpened = reactive<Record<string, boolean>>({})

watch(menuItems, (items) => {
  items.forEach((item) => {
    if (!item.divider && item.children.length > 0 && item.selected) {
      itemOpened[item.to] = true
    }
  })
}, { immediate: true })
</script>
